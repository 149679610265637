<template>
  <v-app>
    <v-card width="650" class="mx-auto my-card">
      <v-card-title><h5>Forgot Password</h5></v-card-title>
      <v-card-text>
        <v-text-field
            filled
            type="email"
            label="Email"
            v-model="user.email"
            :rules="email_rules"
        />
        <v-btn color="red darken-2" dark class="float-left mb-5" @click="sendReset">Submit</v-btn>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      user : {
        email : '',
      },
      email_rules : [
          value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || 'Please enter a valid email'
      ]
    }
  },
  methods : {
    sendReset() {
      this.$store.dispatch('sendResetEmail', this.user)
          .then(res => {
            alert(res)
            this.$router.push('/login')
          })
          .catch(err => {
            alert(err)
          })
    },
  }
}
</script>

<style scoped>

</style>